<template>
  <div>
    <sdPageHeader title="Team">
    </sdPageHeader>
    <Main v-if="errorTeam">
      <sdCards title="">
        loadingTeam
        <a-result status="warning" title="Er is een fout opgetreden bij het ophalen van de gegevens."
                  style="padding: 0">
          <template #extra>
            <Button class="ant-btn ant-btn-primary" type="primary" size="large" @click="$router.replace('/team')">
              Naar teams
            </Button>
          </template>
        </a-result>
      </sdCards>
    </Main>
    <Main v-if="!errorTeam">
      <Upload>
        <div class="user-info-form">
          <BasicFormWrapper class="mb-25">
            <sdCards title="Algemeen">
              <a-skeleton v-if="loadingTeam" active/>
              <a-form v-else :model="form" :rules="rules" layout="vertical" @finish="UpdateTeam">
                <a-row :gutter="30">
                  <a-col :sm="24" :xs="24" class="mb-25">
                    <figure class="photo-upload align-center-v">
                      <img
                          :src="require('@/static/img/avatar/profileImage.png')"
                          alt=""
                      />
                      <figcaption>
                        <a-upload
                            name="file"
                            :multiple="false"
                            :action="`${fileUrl}teams/${form.id}/file`"
                            :headers="fileUploadHeaders"
                            @change="handleUpload">
                          <a class="btn-upload" to="#">
                            <sdFeatherIcons type="camera" size="16"/>
                          </a>
                        </a-upload>
                      </figcaption>
                    </figure>
                  </a-col>
                  <a-col :sm="24" :xs="24" class="mb-25">
                    <a-form-item label="Naam" name="name">
                      <a-input :disabled="!$can('update', 'team')" v-model:value="form.name" placeholder="" />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="30" class="mb-25">
                  <a-col :span="24">
                    <a-form-item label="Omschrijving" name="description">
                      <a-textarea
                          v-model:value="form.description"
                          placeholder=""
                          class="sDash_unresizable"
                          :rows="3"
                          :disabled="!$can('update', 'team')"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :sm="12" :xs="24">
                    <div class="sDash_form-action">
                      <sdButton v-if="$can('update', 'team')"  :disabled="loadingPatchTeam" :loading="loadingPatchTeam" class="btn-signin" type="primary"> Opslaan</sdButton>
                    </div>
                  </a-col>
                </a-row>
              </a-form>
            </sdCards>
          </BasicFormWrapper>
        </div>
      </Upload>
      <sdCards>
        <a-row>
          <a-col :sm="12" :xs="24">
            <div class="sDash_form-action">
              <sdButton class="btn-signin" html-type="submit" type="light" @click="$router.replace('/team')">
                Terug
              </sdButton>
            </div>
          </a-col>
        </a-row>
      </sdCards>
    </Main>
  </div>
</template>

<script>
import {BasicFormWrapper, Main, Upload} from '../styled';
import {computed, defineComponent, onMounted, reactive} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {getItem} from "../../utility/localStorageControl";
import {getApiURL} from "../../config/dataService/dataService";

export default defineComponent({
  name: 'TeamEdit',
  components: {
    BasicFormWrapper,
    Main,
    Upload,
  },
  data() {
    return {
      loading: false,
      fileUrl: getApiURL(),
    }
  },
  setup() {
    const {state, dispatch} = useStore();
    const {params} = useRoute();
    const form = reactive(computed(() => state.team.team));
    const errorTeam = computed(() => state.team.errorTeam);
    const loadingTeam = computed(() => state.team.loadingTeam);
    const loadingPatchTeam = computed(() => state.team.loadingPatchTeam);
    const rules = {
      name: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      description: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
    };
    const UpdateTeam = () => {
      dispatch('patchTeam', {
        id: params.id,
        name: form.value.name,
        description: form.value.description,
      });
    };

    const handleUpload= async (info) => {
      if (info.file.status === 'done') {
        await dispatch('getTeam', params.id);
        await dispatch('fileUploadedSuccess');
      } else if (info.file.status === 'error') {
        await dispatch('fileUploadedError');
      }
    };

    const fileUploadHeaders = {
      Authorization: `Bearer ${getItem('access_token')}`
    };

    onMounted(() => {
      dispatch('getTeam', params.id);
    });

    return {
      form,
      rules,
      errorTeam,
      UpdateTeam,
      loadingPatchTeam,
      handleUpload,
      fileUploadHeaders,
      loadingTeam,
    };
  },
});
</script>
